













































































import PaperPlanIcon from '@/app/ui/assets/paper_plane_icon.vue'
import ListIcon from '@/app/ui/assets/list_icon.vue'
import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjsDuration from 'dayjs/plugin/duration'
import { Utils } from '@/app/infrastructures/misc'
import { CourierPickupDetail, Pickup } from '@/domain/entities/Pickup'

dayjs.extend(dayjsDuration)

@Component({
  components: {
    PaperPlanIcon,
    ListIcon,
  },
})
export default class PickupItemCRRNFD extends Vue {
  @Prop({ required: true }) public no!: number
  @Prop({ required: true }) public data!: Pickup

  get date(): string {
    return Utils.formatDateWithIDLocale(
      this.data.createdAt,
      'DD-MMM-YYYY',
    )
  }

  get time(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        this.data.createdAt,
        'HH:mm Z'
      ),
    )
  }

  get duration(): string {
    const duration = dayjs.duration(dayjs().diff(dayjs(this.data.createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  get overSLA(): boolean {
    return dayjs().diff(dayjs(this.data.createdAt), 'hours') >= 48
  }

  get isBosspack(): boolean {
    return this.data.productType === 'BOSSPACK'
  }

  get courierPickupDetail(): CourierPickupDetail {
    return <CourierPickupDetail>this.data.courierPickupDetail
  }
}
