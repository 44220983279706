





























































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import IconSync from '@/app/ui/assets/icon_sync.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CODSearchInput from '../../COD/components/CODSearchInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import PickupController from '@/app/ui/controllers/PickupController'
import { Utils } from '@/app/infrastructures/misc'
import { Pickup, PickupCRRNFDSummary } from '@/domain/entities/Pickup'
import dayjs from 'dayjs'
import ModalConfirmation from '../components/Modals/ModalConfirmation.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import dayjsDuration from 'dayjs/plugin/duration'
import ModalSuccess from '../components/Modals/ModalSuccess.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import FilterGroup from '@/app/ui/components/FilterGroup/index.vue'
import DropdownCheckbox from '@/app/ui/components/DropdownCheckbox/index.vue'
import ModalExportLog from '../components/Modals/ModalExportLog.vue'


dayjs.extend(dayjsDuration)


@Component({
  components: {
    IconSync,
    Button,
    CODSearchInput,
    DataTableV2,
    ModalConfirmation,
    ModalSuccess,
    PaginationNav,
    LoadingOverlay,
    DropdownSelect,
    FilterGroup,
    DropdownCheckbox,
    ModalExportLog
  },
})
export default class PickupCRRNFDList extends Vue {
  controller = PickupController
  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Order ID', '160px'),
    this.headerCellMapper('Nama Customer', '200px'),
    this.headerCellMapper('No. Handphone', '190px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Date Time', '176px', 'date_time', '20px'),
    this.headerCellMapper('Durasi', '160px', 'duration', '20px'),
    this.headerCellMapper('NFD Status', '140px'),
    this.headerCellMapper('Atur', '180px'),
  ]

  searchOptions = [
    {
      label: 'Order ID',
      value: 'order_id',
    },
    {
      label: 'Customer Name',
      value: 'customer_name',
    },
    {
      label: 'Client Name',
      value: 'client_name',
    },
    {
      label: 'Client ID',
      value: 'client_id',
    },
    {
      label: 'Latest Courier',
      value: 'latest_courier',
    },
  ]

  shipmentTypeOptions = [
    {
      label: 'Delivery Order (DO)',
      value: 'delivery_order',
    },
    {
      label: 'Marketplace (MP)',
      value: 'marketplace',
    },
    {
      label: 'Customer Apps (CA)',
      value: 'customer',
    },
    {
      label: 'Corporate',
      value: 'corporate',
    },
    {
      label: 'COD Balikan',
      value: 'cod_return',
    },
  ]

  productTypeOptions = [
    {
      label: 'Sameday',
      value: 'sameday',
    },
    {
      label: 'Bosspack',
      value: 'bosspack',
    },
    {
      label: 'Regpack',
      value: 'regpack',
    },
    {
      label: 'Jagopack',
      value: 'jagopack',
    },
    {
      label: 'Bigpack',
      value: 'bigpack',
    },
    {
      label: 'Jumbopack',
      value: 'jumbopack',
    },
    {
      label: 'Otopack 150',
      value: 'otopack150',
    },
    {
      label: 'Otopack 250',
      value: 'otopack250',
    },
  ]

  cancelReasonOptions = [
    {
      label: '-',
      value: '-',
    },
    {
      label: 'Customer Ingin Mengubah Jadwal Pickup/Pengantaran',
      value: 'PAGLH',
    },
    {
      label: 'Customer Sulit Dihubungi',
      value: 'CSTSD',
    },
    {
      label: 'Customer Tidak Berada di Lokasi',
      value: 'PNTDA',
    },
    {
      label: 'Sudah Melewati Batas Waktu Pickup',
      value: 'SMBWP',
    },
    {
      label: 'Toko Tutup',
      value: 'TKTP',
    },
    {
      label: 'Produk Pesanan Tidak Tersedia/habis',
      value: 'PPTT',
    },
    {
      label: 'Paket Belum Siap di Pickup',
      value: 'BBSDP',
    },
    {
      label: 'Customer Ingin Mengubah Ekspedisi',
      value: 'CSTME',
    },
    {
      label: 'Lokasi Pickup Terlalu Jauh',
      value: 'APTLJ',
    },
    {
      label: 'Kendala Motor Atau Ponsel',
      value: 'KNRSK',
    },
    {
      label: 'Customer Dropoff ke POS/HUB Terdekat',
      value: 'DROP',
    },
    {
      label: 'Paket Diambil Kurir Lain',
      value: 'PKDKL',
    },
    {
      label: 'Alamat Tidak Ketemu',
      value: 'ALMSL',
    },
    {
      label: 'Lokasi Tutup Atau Pindah ',
      value: 'TLPST',
    },
    {
      label: 'Force Majeur',
      value: 'KLTDM',
    },
    {
      label: 'Dibatalkan oleh Admin',
      value: 'ADMIN',
    },
    {
      label: 'Lokasi Tutup',
      value: 'LKTTP',
    },
    {
      label: 'Lokasi Pindah',
      value: 'LKPDH',
    },
    {
      label: 'Kendala lainnya',
      value: 'ZZZZZ',
    },
  ]

  NFDStatusOptions = [
    {
      label: '1 NFD',
      value: '1',
    },
    {
      label: '2 NFD',
      value: '2',
    },
    {
      label: '3 NFD',
      value: '3',
    },
    {
      label: '4 NFD',
      value: '4',
    },
    {
      label: '5 NFD',
      value: '5',
    },
    {
      label: '>5 NFD',
      value: '-1',
    },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
    searchBy: this.searchOptions[0],
    shipmentType: [],
    productType: [],
    cancelReasonType: [],
    totalNfdStatus: <DropDownLabelValue<string | number> | null>null,
    sort: {
      name: '',
      direction: '',
    },
  }

  tableData: DataObject[][] = []
  modalCRRSRCVisible = false
  CRRSRCshipmentId = ''
  modalSuccessCRRSRCVisible = false
  showModalExportLog = false

  created(): void {
    this.fetchPickup(true)
  }

  get params(): Record<string, string | number> {
    return {
      statusId: 'CRRNFD',
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      dateFrom: Utils.formatDateWithIDLocale(
        new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
        'YYYY-MM-DD'
      ),
      keyword: this.parameters.search,
      keywordField: <string>this.parameters.searchBy.value || '',
      shipmentType: <string>(
        this.parameters.shipmentType
          ?.map(
            type =>
              this.shipmentTypeOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      productType: <string>(
        this.parameters.productType
          .map(type =>
            this.productTypeOptions
              .find(option => option.label === type)
              ?.value.toUpperCase()
          )
          .join(',')
      ),
      reasonId: <string>(
        this.parameters.cancelReasonType
          .map(
            type =>
              this.cancelReasonOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      totalNfdStatus: this.parameters.totalNfdStatus?.value || '',
      sortField: this.parameters.sort.name,
      sortDirection: this.parameters.sort.direction,
    }
  }

  get latestSync(): string {
    if (!this.controller.latestSync) {
      return ''
    }

    return dayjs(this.controller.latestSync).format('DD-MM-YY HH:mm')
  }

  get filterCounterBadge(): number {
    let total =
      this.parameters.cancelReasonType.length +
      this.parameters.productType.length
    return this.parameters.totalNfdStatus?.value ? total + 1 : total
  }

  get summaryData(): PickupCRRNFDSummary {
    return this.controller.CRRNFDSummaryData
  }

  get totalSummaryData(): number {
    return (
      (this.summaryData.one || 0) +
      (this.summaryData.two || 0) +
      (this.summaryData.three || 0) +
      (this.summaryData.four || 0) +
      (this.summaryData.five || 0) +
      (this.summaryData.moreThanFive || 0)
    )
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    sortName?: string,
    sortWidth?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort: Boolean(sortName),
      sortName: sortName,
      sortStyle: {
        marginLeft: sortWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | string[] | boolean[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  public fetchPickup(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getPickupList(this.params)
    this.controller.fetchCRRNFDSummary()
  }

  public onForceCRRSRC(): void {
    this.modalCRRSRCVisible = false
    this.controller.forceCRRSRC(this.CRRSRCshipmentId)
  }

  public handleExportCRRNFD(): void {
    const params = {
      file_name: `CRRNFD-${Utils.formatDateWithIDLocale(
        dayjs(),
        'YYYYMMDDHHmss'
      )}`,
      date_from: Utils.formatDate(dayjs().subtract(1, 'week'), 'YYYY-MM-DD'),
      sort_direction: 'ASC',
    }

    this.controller.exportCRRNFD(params)
  }

  public onSearch = Utils.debounce(() => {
    this.fetchPickup(true)
  }, 400)

  public formatNFDStatus(value: string | number): string {
    const status = parseInt(<string>value)
    if (status === 1) return 'one'
    if (status === 2) return 'two'
    if (status === 3) return 'three'
    if (status === 4) return 'four'
    if (status === 5) return 'five'
    return 'more'
  }

  public getOverSLA(createdAt: string): boolean {
    return dayjs().diff(dayjs(createdAt), 'hours') >= 48
  }

  public isBosspack(productType: string): boolean {
    return productType === 'BOSSPACK'
  }

  public getDuration(createdAt: string): string {
    const duration = dayjs.duration(dayjs().diff(dayjs(createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  public getPickupData(index: number): Pickup {
    return this.controller.pickupData[index]
  }

  public getlastCourier(index: number): string {
    const crr = this.controller.pickupData[index].courierPickupDetail
    if (!crr) return ''
    return `[${crr.courierId}] ${crr.courierName} (${crr.courierTypeValid}) ${crr.phoneNumber} • ${crr.partnerName}`
  }

  public onFilterReset(): void {
    this.parameters = {
      ...this.parameters,
      cancelReasonType: [],
      productType: [],
      totalNfdStatus: null,
    }

    this.fetchPickup(true)
  }

  @Watch('controller.pickupData')
  onPickupDataChange(data: Pickup[]): void {
    this.tableData = data.map((pickup, index) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px'
        ),
        this.tableCellMapper(<string>pickup.shipmentId, '160px'),
        this.tableCellMapper(
          [
            <string>pickup.pickup?.fullname || '-',
            `(${pickup.clientId} - ${pickup.clientName})`,
            <string>pickup.clientId,
          ],
          '200px'
        ),
        this.tableCellMapper(<string>pickup.pickup?.phoneNumber || '-', '190px'),
        this.tableCellMapper(
          [
            this.getOverSLA(<string>pickup.history.createdAt),
            <boolean>pickup.isOnepack,
            this.isBosspack(<string>pickup.productType),
          ],
          '140px'
        ),
        this.tableCellMapper(
          <string[]>[
            Utils.formatDateWithIDLocale(
              <string>pickup.history.createdAt,
              'DD MMM YYYY'
            ),
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(pickup.history.createdAt, 'HH:mm Z')
            ),
          ],
          '176px'
        ),
        this.tableCellMapper(this.getDuration(pickup.history.createdAt), '160px'),
        this.tableCellMapper(pickup.history.totalNfd, '140px'),
        this.tableCellMapper(
          [
            <string>pickup.shipmentId,
            `${pickup.pickup?.latitude}:${pickup.pickup?.longitude}`,
            pickup.shipmentId.substr(0, 2) === 'C1' ? 'true' : '',
          ],
          '180px'
        ),
      ]
    })
  }

  @Watch('controller.isForceCRRSRCSuccess')
  onIsForceCRRSRCSuccessChange(data: boolean): void {
    if (data) {
      this.modalSuccessCRRSRCVisible = true
      this.fetchPickup(true)
      this.controller.setIsForceCRRSRCSuccess(false)
    }
  }
}
